<template>
    <div>
        <div class="error-page">
            <!-- <div class="error-code">4<span>0</span>4</div> -->
            <div class="error-desc" v-if='isValidating'>信息验证中……</div>
            <div class="error-desc" v-else="">{{msg}}</div>
            <!-- <div class="error-handle">
            <router-link to="/">
                <el-button type="primary" size="large">进入系统</el-button>
            </router-link>
            <el-button class="error-btn" type="primary" size="large" @click="goBack">关闭</el-button>
        </div> -->
        </div>
        <div v-show="!isValidating" class="error_end">——高擎论文评审中心</div>
    </div>
</template>

<script>
    import {
        setToken
    } from '@/tools/auth'
    export default {
        name: 'explanation',
        data() {
            return {
                msg: '',
                isValidating: true
            }
        },
        created() {
            let token = this.$route.query['token'];
            this.tokenLogin(token);
        },
        methods: {
            //链接登录
            tokenLogin(token) {
                console.log(token);
                let param = new URLSearchParams();
                param.append("tokenID", token);
                this.$api.user.getToken(param)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.isValidating = false;
                            this.$store.dispatch('professor/changeAccount', res.data.data.username);
                            let inner = res.data.data;
                            if (inner.code == 2001 || inner.code == 2002 || inner.code == 2006) {
                                // 获取用户信息
                                this.$api.user.getUser()
                                    .then(res => {
                                        if (res.data.code == 200) {
                                            let name = res.data.data.username;
                                            let institution = res.data.data.institution;
                                            this.$store.dispatch('professor/changeInfo', {
                                                name,
                                                institution
                                            });
                                            setToken(1);
                                            this.$router.push({
                                                name: 'unfinishedList'
                                            })
                                        }
                                    })


                            } else {
                                this.msg = inner.message;
                            }
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
                // this.$axios.get("/user/valid_token", {
                //     params: param
                // }).then(res => {
                //     this.isValidating = false;
                //     if (res.data.code == 200) {
                //         sessionStorage.setItem("expertId", res.data.data.expertId);
                //         sessionStorage.setItem("account", res.data.data.expertId);
                //         sessionStorage.setItem('username', res.data.data.expertName);
                //         sessionStorage.setItem('university', res.data.data.university);
                //         this.$store.dispatch('changeIsLogin', 1);
                //         this.$router.push({
                //             name: "professorIndex"
                //         });
                //     } else {
                //         this.msg = res.data.msg;
                //         // this.msg = this.$store.state.mapper.mailMap[res.data.code];
                //         // this.$message.warning("身份验证以过期！");
                //     }
                // }).catch(err => {
                //     this.$message.warning("服务器维护！");
                // })
            },
            goBack() {

            }
        }
    }
</script>


<style scoped>
    .error-page {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        /* background: #f3f3f3; */
        box-sizing: border-box;
    }

    .error-code {
        line-height: 1;
        font-size: 250px;
        font-weight: bolder;
        color: #2d8cf0;
    }

    .error-code span {
        color: #00a854;
    }

    .error-desc {
        padding-top: 10px;
        font-size: 30px;
        color: #777;
        font-family: "楷体";
    }

    .error_end {
        padding-top: 10px;
        padding-right: 50px;
        font-size: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .error-handle {
        margin-top: 30px;
        padding-bottom: 200px;
    }

    .error-btn {
        margin-left: 100px;
    }
</style>